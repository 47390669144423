<template>
  <div class="container">
    <div class="main">
      <ul class="news_ul">
        <li class="news_li"
            v-for="item in newsList"
            :key="item.title+index">
          <div class="img">
            <img src=""
                 alt="">
          </div>
          <div class="text">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.info }}</div>
            <div class="dsc">详情</div>
          </div>
          <div class="time">
            <div class="day">23</div>
            <div class="year_month">2021-05</div>
          </div>
        </li>
      </ul>
      <div class="page_fenye">
        <el-pagination background
                       layout="prev, pager, next"
                       :total="100">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newsList: [
        { title: '新闻标题', info: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容' },
        { title: '新闻标题', info: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容' },
        { title: '新闻标题', info: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容' },
        { title: '新闻标题', info: '新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容' },
      ]
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/news.scss";
</style>